.main-bg {
  background: url("/img/root/bg.jpg") no-repeat center/cover;
}

.face-img {
  width: 221px;
  height: 173px;
}

@media (min-width: 768px) {
  .face-img {
    width: 269px;
    height: 211px;
  }
}